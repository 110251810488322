import React, { Fragment, useCallback } from 'react';
import "./Header.scss";
import { Link, useLocation } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { LinkContainer } from 'react-router-bootstrap';
import { useState, useEffect } from 'react';
import { HeaderMenuData} from './HeaderMenuData';
import Offcanvas from 'react-bootstrap/Offcanvas';

function Header() {

  const [offcanvasShow, setOffcanvasShow] = useState(false);
  const location = useLocation();
  const [isGetUrl, setIsGetUrl] = useState('');
  const [navClass, setNavClass] = useState('');

  const getUrlValues = HeaderMenuData.flatMap(header =>
    header.ProductMenuData.map(product => product.urls)
  );

  const initialNavClass = useCallback(() => {
    const pathname = location.pathname;
    if (pathname === isGetUrl || getUrlValues.includes(pathname)) {
      return 'nav-desktop';
    }
    return '';
  }, [location.pathname, isGetUrl, getUrlValues]);

  useEffect(() => {
    const className = initialNavClass();
    setNavClass(className);
  }, [location.pathname, initialNavClass]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991) setOffcanvasShow(false);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLinkClick = (url) => {
    setIsGetUrl(url);
  };

  return (
    <>

    <Navbar fixed="top" expand="lg" bg="light" className={`${navClass}`}>
      <Container>
        <span>
          <Navbar.Toggle aria-controls="offcanvasNavbar-expand" onClick={() => setOffcanvasShow(true)} />
          <Link to="/" className="navbar-brand qkr-biz">krbiz</Link>
        </span>
        <Link to="https://signin.qkrbiz.com" className="btn btn-outline-magenta btn-login" target='_blank'>Login</Link>
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand"
          aria-labelledby="offcanvasNavbarLabel-expand"
          placement="start"
          show={offcanvasShow}
          onHide={() => setOffcanvasShow(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel-expand">
              <Link to="/" className="navbar-brand qkr-biz">krbiz</Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="ms-auto">
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <NavDropdown title="Products" id="offcanvasNavbarDropdown-expand" className="mega-menu fullscreen-menu">
                <div className="container">
                  {HeaderMenuData.map((header, hIndex) => (
                    <div className="row" key={hIndex}>
                      <div className="col-lg-6 product-feature">
                        <h4>{header.head_1}</h4>
                        <div className="row">
                          {header.ProductMenuData.map((productItem, productindex) => (
                            <div className="col-lg-6" key={productindex}>
                              <LinkContainer 
                              to={productItem.urls} 
                              className={productItem.productClass} 
                              onClick={() => { 
                                handleLinkClick(productItem.urls);
                                setOffcanvasShow(false);
                              }}>
                                <NavDropdown.Item>
                                  <span className="logo-part">
                                    <span  className={`logo ${productItem.productClass}`}></span>
                                    <span className="titles">
                                      <h5>{productItem.title}</h5>
                                      <p>{productItem.desc}</p>
                                    </span>
                                  </span>
                                </NavDropdown.Item>
                              </LinkContainer>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-lg-6 platform-feature">
                        <h4>{header.head_2}</h4>
                        <div className="row">
                          {header.PlatformFeaturData.map((pData, Pindex) => (
                            <div className="col-lg-6" key={Pindex}>
                              <LinkContainer to={pData.url} onClick={() => setOffcanvasShow(false)}>
                                <NavDropdown.Item>
                                  <span className="logo-part">
                                    <span  className={`logo ${pData.classNames}`}></span>
                                    <span className="titles">
                                      <h5>{pData.title}</h5>
                                      <p>{pData.desc}</p>
                                    </span>
                                  </span>
                                </NavDropdown.Item>
                              </LinkContainer>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </NavDropdown>
              <LinkContainer to="/customers">
                <Nav.Link>Customers</Nav.Link>
              </LinkContainer>
              <NavDropdown title="Company" id="offcanvasNavbarDropdown-expand" className="company-menu">
                <LinkContainer to="/about" onClick={() => {setOffcanvasShow(false)}}>
                  <NavDropdown.Item>About Us</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/contact" onClick={() => {setOffcanvasShow(false)}}>
                  <NavDropdown.Item>Contact Us</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <NavDropdown title="Resources" id="offcanvasNavbarDropdown-expand" className="resource-menu">
                {HeaderMenuData.map((headerRe, pfIndex) => (
                  <Fragment key={pfIndex}>
                    <NavDropdown title="Platform Features" id="offcanvasNavbarDropdown-expand" className="dropdown-submenu">
                      {headerRe.PlatformFeaturData.map((prData, prindex) => (
                        <Fragment key={prindex}>
                          <LinkContainer to={prData.url} onClick={() => {setOffcanvasShow(false)}}>
                            <NavDropdown.Item>{prData.title}</NavDropdown.Item>
                          </LinkContainer>
                        </Fragment>
                      ))}
                    </NavDropdown>
                  </Fragment>
                ))}
                <LinkContainer to="/blog">
                  <NavDropdown.Item>Blog </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            </Nav>
            <form className="ms-auto nav-form">
              <Link to="https://signin.qkrbiz.com" className="btn btn-outline-magenta" target="_blank">Login</Link>
              <Link to="/signup" className="btn btn-magenta">Free trial</Link>
            </form>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>

    </>
  );
}

export default Header;
import React, { useState, useEffect, useRef, Fragment } from "react";
import Slider from "react-slick";
import "./TabsScroll.scss";
// import { TabsScrollData } from "./TabsScrollData.js";
import VideoAutoPlay from "../Video/VideoAutoPlay.js";
import VideoWindowPlay from "../Video/VideoWindowPlay.js";

const TabsScroll = ({TabScrollData, addCls}) =>{
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);


  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const settings = {
    arrows:false,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 9,
    swipeToSlide: true,
    focusOnSelect: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
        slidesToShow: 5
        }
      },
      {
        breakpoint: 768,
        settings: {
        slidesToShow: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
        slidesToShow: 3
        }
      },
      {
        breakpoint: 320,
        settings: {
        slidesToShow: 2
        }
      }
    ]
  };
  const setting = {
    fade: true,
    swipeToSlide: true,
    focusOnSelect: true,
    arrows:false,
    speed: 500,
  }

  return (
    <>     
      <div className="container">
        <div className="multitab_content nav-tabs">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-xl-11 col-xxl-9">
              <div className={`slider-container ${addCls}`}>
                <div className="heading-title text-center"><h1 className="under-line mb-3">Your Data, Your View, Your Way</h1></div>
                <p className="fn-3 text-center mb-5">Empower Your Team with Flexible Visualization
                </p>
                <div className="tabs-left">
                  <Slider
                    asNavFor={nav1}
                    ref={(slider) => (sliderRef2 = slider)}
                    {...settings}
                  >
                    {TabScrollData.map((topItem, topIndex) => (
                      <div className="topdiv" key={topIndex}>
                        <div className="icons">
                           <span className={`img ${topItem.tabIcon}`}></span>
                          {/* <img src={topItem.tabIcon} alt={topItem.tabName} /> */}
                          {/* <span dangerouslySetInnerHTML={{ __html: topItem.tabIcon }}></span> */}
                        </div>
                        <div className="text">{topItem.tabName}</div>
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="tab-content">
                  <Slider
                  asNavFor={nav2}
                  ref={(slider) => (sliderRef1 = slider)}
                  {...setting}
                  >
                    {TabScrollData.map((innerItem, innerIndex) => (
                      <div className="tab-panel" key={innerIndex}>
                        {innerItem.navList.map((item, nestedIndex) => (
                          <Fragment key={nestedIndex}>
                              {item.videoUrl ? (
                                  item.videoAuto === true ? (
                                    <VideoAutoPlay 
                                      videoSource={item.videoUrl} 
                                      videoPoster={item.imgUrl} 
                                      videoShadow="" 
                                      radius="radius-1" 
                                    />
                                  ) : (
                                    <VideoWindowPlay 
                                      videoSource={item.videoUrl} 
                                      videoPoster={item.imgUrl} 
                                      videoShadow="" 
                                      radius="radius-1" 
                                    />
                                  )
                              )
                              :(
                              <img className="img-fluid" src={item.imgUrl} alt={item.title}  key={nestedIndex} />
                            )}
                          </Fragment>
                        ))}
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TabsScroll;

const ImagesUrl = {
	QkrbizLogo: require('./appicon/Qkrbiz.svg').default,
	QkrbizLoader: require('./qkrbiz-loader.gif'),
	GooglePlay: require('./google-play.svg').default,
	AppleIcon: require('./apple.svg').default,
	HomeBannerImage: require('./home-banner.svg').default,
	HomeBanner: require('./homebannerBg.png'),
	HomeBottomImage: require('./home-bottom.svg').default,
	kiteIcon: require('./kiteIcon.svg').default,
	PlatformArrow: require('./platform-arow.svg').default,
	FreeTrailBG:require ('./FreeTrailBG.png'),
	FreeTrailGif:require ('./Free-Trail.gif'),
	Location:require ('./location-pin.png'),
	TickPink:require ('./tick-pink.svg').default,
	// SearchText:require('./SearchText.svg').default,
	platformfeatures:{
		PfpreadsheetFeatures:require('./platformFeature/PfSpreadsheetfeatures.svg').default,
		PfView:require('./platformFeature/PfViews.svg').default,
		PfAlertsNotification:require('./platformFeature/PfAlertsNotifications.svg').default,
		PfAutomationWorkflow:require('./platformFeature/PfAutomateworkflow.svg').default,
		PfDocuments:require('./platformFeature/PfDocuments.svg').default,
		PfSecurity:require('./platformFeature/PfSecurity.svg').default,
		PfBIAnalytics:require('./platformFeature/PfBIAnalytics.svg').default,
		PfCustomizations:require('./platformFeature/PfCustomizations.svg').default,
		PfMobileApp:require('./platformFeature/PfMobileApp.svg').default,
		PfBoards:require('./platformFeature/Pfboards.svg').default,
	},
	accountable :{
		SecureAccess: require ('./SecureAccess.svg').default,
		RoleBased: require ('./RoleBased.svg').default,
		ControlOver: require ('./ControlOver.svg').default,
		DataLevel: require ('./DataLevel.svg').default,
	},
	EffortlessExperience:{
		simplifiedBox: require('./SimplifiedBox.svg').default,
		familiarSpreadsheet: require('./FamiliarSpreadsheet.svg').default,
		clickAnywhere: require('./ClickAnywhere.svg').default,
		commandControl: require('./CommandControl.svg').default,
		employeeSelf: require('./EmployeeSelf.svg').default,
		customViews: require('./CustomViews.svg').default,
	},
	about:{
		OurTeamMember_1: require ('./OurTeamMember_1.png'),
		OurTeamMember_2: require ('./OurTeamMember_2.png'),
		OurTeamMember_3: require ('./OurTeamMember_3.png'),
		ClientsSay: require ('./ClientsSay.png'),
	},
	customers:{
		Customers: require ('./Customers.png'),
		customersheader: require ('./Customersheader.png'),
	},
	client:{
		ClientsImage1: require ('./client/iit-bhu.png'),
		ClientsImage2: require ('./client/swastik.png'),
		ClientsImage3: require ('./client/beltron.png'),
		ClientsImage4: require ('./client/bhanuprakash.png'),
		ClientsImage5: require ('./client/capitalpower.png'),
		ClientsImage6: require ('./client/dsk-enterprises.png'),
		ClientsImage7: require ('./client/nexgen.png'),
		ClientsImage8: require ('./client/hindustanherbal.png'),
		ClientsImage9: require ('./client/hitech.png'),
		ClientsImage10: require ('./client/iit_roorkee.png'),
		ClientsImage11: require ('./client/alacrit.png'),
		ClientsImage12: require ('./client/kde.png'),
		ClientsImage13: require ('./client/regard.png'),
		ClientsImage14: require ('./client/shalimarpaint.png'),
		ClientsImage15: require ('./client/skenterprises.png'),
		ClientsImage16: require ('./client/pousnyfood.png'),
		ClientsImage17: require ('./client/kingmaker.png'),
		ClientsImage18: require ('./client/kothari.jpg'),
		ClientsImage19: require ('./client/fire_solution.png'),
		ClientsImage20: require ('./client/raj-group.png'),
		ClientsImage21: require ('./client/anivet-helthcare.png'),
		ClientsImage22: require ('./client/visionplus.png')
	},
	clientDetail:{
		clientsAshok_jade :require ('./ashok_jade.png'),
		clientsDinesh_gupta :require ('./dinesh_gupta.png'),
		clientsRanjit_singh :require ('./ranjit_singh.png'),
		clientsAtal_vihari :require ('./atal_vihari.png'),
	},
	MultiTab:{
		SpreadSheets: require ('./feature/multitab/spreadSheets.png'),
		Boards: require ('./feature/multitab/boards.png'),
		FormView: require ('./feature/multitab/formView.png'),
		CardView: require ('./feature/multitab/cardView.png'),
		PivotTable: require ('./feature/multitab/pivotTable.png'),
		CalendarView: require ('./feature/multitab/calendarView.png'),
		Map: require ('./feature/multitab/map.png'),
		ListView: require ('./feature/multitab/listView.png'),
		Dashboard: require ('./feature/multitab/dashboard.png'),
		ChatView: require ('./feature/multitab/chatView.png'),
		Payroll:require ('./feature/multitab/Payroll.png'),
		EmployeeProfile:require('./feature/multitab/EmployeeProfile.jpg'),
	},
	TabScroll:{
		TabIconSpreadSheets: require ('./feature/TabScroll/TabIconSpreadSheets.svg').default,
		TabIconBoards: require ('./feature/TabScroll/TabIconBoards.svg').default,
		TabIconForm: require ('./feature/TabScroll/TabIconForm.svg').default,
		TabIconCard: require ('./feature/TabScroll/TabIconCard.svg').default,
		TabIconPivotTable: require ('./feature/TabScroll/TabIconPivotTable.svg').default,
		TabIconCalendar: require ('./feature/TabScroll/TabIconCalendar.svg').default,
		TabIconMap: require ('./feature/TabScroll/TabIconMap.svg').default,
		TabIconList: require ('./feature/TabScroll/TabIconList.svg').default,
		TabIconDashboard: require ('./feature/TabScroll/TabIconDashboard.svg').default,
		TabIconChat: require ('./feature/TabScroll/TabIconChat.svg').default,
		Tabspredsheet:require('./feature/TabScroll/tabspredsheet.png'),
		Tabboard:require('./feature/TabScroll/tabboard.png'),
		Tabcard:require('./feature/TabScroll/tabcard.jpg'),
		Tabpivottable:require('./feature/TabScroll/tabpivottable.png'),
		Tabmap:require('./feature/TabScroll/tabmap.png'),
		Tablist:require('./feature/TabScroll/tablist.png'),
		Tabdashboard:require('./feature/TabScroll/tabdashboard.png'),
		Tabcalendar:require('./feature/TabScroll/tabcalendar.png'),
		Tabchat:require('./feature/TabScroll/tabchat.png'),
		Tabform:require('./feature/TabScroll/tabform.png'),
	},
	view:{
		view_img: require ('./feature/View/view_img.png'),
		PayrollDashboard: require ('./feature/View/PayrollDashboard.png'),
		spreadsheets: require ('./feature/View/spreadsheets.png'),
		boardview: require ('./feature/View/boardview.png'),
		formview: require ('./feature/View/formview.png'),
		cardview: require ('./feature/View/cardview.png'),
		pivottables: require ('./feature/View/pivottables.png'),
		calendarview: require ('./feature/View/calendarview.png'),
		mapview: require ('./feature/View/mapview.png'),
		listview: require ('./feature/View/listview.png'),
		Dashboardview: require ('./feature/View/Dashboardview.png'),
	},
	feature:{
		arrows: require ('./feature/SpreadsheetFeatures/arrow.svg').default,
		ActionMenus: require ('./feature/SpreadsheetFeatures/ActionMenus.png'),
		Search: require ('./feature/SpreadsheetFeatures/Search.png'),
		Filter: require ('./feature/SpreadsheetFeatures/ColumnFilters.png'),
		ColumnFilters: require ('./feature/SpreadsheetFeatures/ColumnFilters.png'),
		PreconfiguredFilters:require ('./feature/SpreadsheetFeatures/PreconfiguredFilters.png'),
		Sort: require ('./feature/SpreadsheetFeatures/Sort.png'),
		MultiSort: require ('./feature/SpreadsheetFeatures/MultiSort.png'),
		FreezeColumns: require ('./feature/SpreadsheetFeatures/FreezeColumns.png'),	
		HideColumns: require ('./feature/SpreadsheetFeatures/HideColumns.png'),
		AddColumns: require ('./feature/SpreadsheetFeatures/AddColumns.png'),
		ResizeColumns: require ('./feature/SpreadsheetFeatures/ResizeColumns.png'),
		WrapText: require ('./feature/SpreadsheetFeatures/WrapText.png'),
		RearrangeColumns: require ('./feature/SpreadsheetFeatures/RearrangeColumns.png'),
		ViewColumnTotals: require ('./feature/SpreadsheetFeatures/ViewColumnTotals.png'),
		CustomViews: require ('./feature/SpreadsheetFeatures/CustomViews.png'),
		ExportExcel: require ('./feature/SpreadsheetFeatures/ExportExcel.png'),
		Zoom: require ('./feature/SpreadsheetFeatures/Zoom.png'),
		ResetColumnWidth: require ('./feature/SpreadsheetFeatures/ResetColumnWidth.png'),
		FullScreen: require ('./feature/SpreadsheetFeatures/FullScreen.png'),
		PivotTables: require ('./feature/SpreadsheetFeatures/PivotTables.png'),
		CardView: require ('./feature/SpreadsheetFeatures/CardView.png'),
		// PayrollSoftware:require ('https://d2w2i7rp1a0wob.cloudfront.net/static/images/home/payroll-software-keka.svg').default,
	},
	Alertnotification:{
		AlertsNotificationHeader: require ('./feature/AlertsNotifications/AlertsNotificationHeader.jpg'),
		Updates:require ('./feature/AlertsNotifications/Updates.png'),
		Reminders:require ('./feature/AlertsNotifications/Reminders.png'),
		MIS:require ('./feature/AlertsNotifications/MIS.png'),
		AlertsNotifications: require ('./feature/AlertsNotifications/AlertsNotification.png'),
		ConfigureRecipients: require ('./feature/AlertsNotifications/ConfigureRecipients.png'),
		EscalationAlerts: require ('./feature/AlertsNotifications/EscalationAlerts.png'),
		MISReports: require ('./feature/AlertsNotifications/MISReports.png'),
	},
	Document:{
		HocumentManagmentHeader:require('./feature/Document/HocumentManagmentHeader.png'),
		SharedDocuments: require ('./feature/Document/SharedDocuments.svg').default,
		TeamDocuments: require ('./feature/Document/TeamDocuments.svg').default,
		MyDocuments: require ('./feature/Document/MyDocuments.svg').default,
		CreateLetter: require ('./feature/Document/CreateLetters.png'),
		AllDocument: require ('./feature/Document/AllDocuments.png'),
		AccessDocuments: require ('./feature/Document/AccessDocuments.png'),
	},
	workflow:{
		AutomateWorkflowHeader:require ('./feature/Automateworkflow/AutomateWorkflowHeader.png'),
		EnhanceEfficiency:require ('./feature/Automateworkflow/EnhanceEfficiency.png'),
		ImproveDecision:require ('./feature/Automateworkflow/ImproveDecisionMaking.png'),
		EliminateErrors:require ('./feature/Automateworkflow/EliminateErrors.png'),
		ImproveProductivity:require ('./feature/Automateworkflow/ImproveProductivity.png'),
		SpecificallyHR:require ('./feature/Automateworkflow/SpecificallyHR.png'),
		RecurringBusiness:require ('./feature/Automateworkflow/StreamlineRecuring.png'),
		CustomizeForms:require ('./feature/Automateworkflow/CustomizeForms.svg').default,
		CustomizeNotification:require ('./feature/Automateworkflow/CustomizeNotification.svg').default,
		AttachDigital:require ('./feature/Automateworkflow/AttachDigital.svg').default,
		CentralizeApproval:require ('./feature/Automateworkflow/CentralizeApproval.png'),
		RemindersPending:require ('./feature/Automateworkflow/RemindersPending.svg').default,
		TrulyPaperLess:require ('./feature/Automateworkflow/TrulyPaperLess.svg').default,
		PendingApprovals:require ('./feature/Automateworkflow/PendingApprovals.png'),
		NotificationManage:require ('./feature/Automateworkflow/NotificationManage.png'),
		ApproveExpenses:require ('./feature/Automateworkflow/ApproveExpenses.png'),
		ApprovalWorkFlow:require ('./feature/Automateworkflow/ApprovalWorkFlow.png'),
	},
	BoardView:{
		BoardviewHead:require ('./feature/BoardView/BoardviewHead.png'),
		ActionMenus_b:require ('./feature/BoardView/ActionMenus_b.png'),
		Columns_b:require ('./feature/BoardView/Columns_b.png'),
		CustomFilters_b:require ('./feature/BoardView/CustomFilters_b.png'),
		DesignCards_b:require ('./feature/BoardView/DesignCards_b.png'),
		KanbanBoard_b:require ('./feature/BoardView/KanbanBoard_b.png'),
		MultipleBoard_b:require ('./feature/BoardView/MultipleBoard_b.png'),
		PreconfiguredFilters_b:require ('./feature/BoardView/PreconfiguredFilters_b.png'),
		ShareBoards_b:require ('./feature/BoardView/ShareBoards_b.png'),	
	},
	Security:{
		SecurityHeader: require ('./feature/Security/SecurityHeader.png'),
		DataPrivacy: require('./feature/Security/DataPrivacy.svg').default,
		RestrictedAccess: require('./feature/Security/RestrictedAccess.svg').default,
		DataSegregation: require('./feature/Security/DataSegregation.svg').default,
		NoLocalStorage: require('./feature/Security/NoLocalStorage.svg').default,
		SecureToken: require('./feature/Security/SecureToken.svg').default,
		PasswordPolicy:require('./feature/Security/PasswordPolicy.png'),
		Infrastructure: require ('./feature/Security/Infrastructure.png'),
		DataCenter: require ('./feature/Security/DataCenter.png'),
		PhysicalMonitoring: require('./feature/Security/PhysicalMonitoring.svg').default,
		ClimateControl: require('./feature/Security/ClimateControl.svg').default,
		SecureNetwork: require('./feature/Security/SecureNetwork.svg').default,
		AuthorizedPersonnel: require('./feature/Security/AuthorizedPersonnel.svg').default,
		EnhancedHardware: require('./feature/Security/EnhancedHardware.svg').default,
		DisasterRisk: require('./feature/Security/DisasterRisk.svg').default,
		HostedApplication:require('./feature/Security/HostedApplication.png'),
		NetworkLevel:require('./feature/Security/NetworkLevel.png'),
		IdentityAccess:require('./feature/Security/IdentityAccess.png'),
		InfrastructureLevel:require('./feature/Security/InfrastructureLevel.png'),
		DataSecurityArrow: require ('./feature/Security/DataSecurityArrow.svg').default,
	},
	custom:{
		CustomFields: require ('./feature/Customizations/CustomFields.png'),
		CustomAddonTab: require ('./feature/Customizations/CustomAddonTab.png'),
		CustomForms: require ('./feature/Customizations/CustomForms.png'),
		CustomTableViews: require ('./feature/Customizations/CustomTableViews.png'),
		CustomKanbanBoards: require ('./feature/Customizations/CustomKanbanBoards.png'),
		CustomListViews: require ('./feature/Customizations/CustomListViews.png'),

		CustomGenerateLetters: require ('./feature/Customizations/CustomGenerateLetters.png'),
		CustomMISReports: require ('./feature/Customizations/CustomMISReports.png'),
		CustomizeTemplates: require ('./feature/Customizations/CustomizeTemplates.png'),
		CustomColors: require ('./feature/Customizations/CustomColors.png'),
		CustomizeExpenseForm: require ('./feature/Customizations/CustomizeExpenseForm.png'),
		CustomRoles: require ('./feature/Customizations/CustomRoles.png'),
		CustomWorkflows: require ('./feature/Customizations/CustomWorkflows.png'),
		CustomPivotTables: require ('./feature/Customizations/CustomPivotTables.png'),
	},
    bIAnalytics:{
		actionable: require ('./feature/Bianalytics/ActionableInsights.svg').default,
		customizableDash: require ('./feature/Bianalytics/CustomizableDashboards.svg').default,
		interactive: require ('./feature/Bianalytics/Interactive.svg').default,
		intelligentAlerts: require ('./feature/Bianalytics/IntelligentAlerts.svg').default,
		customizableAna: require ('./feature/Bianalytics/CustomizableAnalytics.svg').default,
		colourReports: require ('./feature/Bianalytics/ColourReports.svg').default,
		BiHead: require ('./feature/Bianalytics/BiHead.png'),
		BiDashboard: require ('./feature/Bianalytics/BiDashboard.png'),
		Ring: require ('./feature/Bianalytics/Ring.png'),
		RingDesign: require ('./feature/Bianalytics/RingDesign.svg').default,
		ActionableInsights: require ('./feature/Bianalytics/ActionableInsights.png'),
		EnhanceVisibility: require ('./feature/Bianalytics/EnhanceVisibility.png'),
		IntelligentAlerts: require ('./feature/Bianalytics/IntelligentAlerts.png'),
		Interactiveisualizations: require ('./feature/Bianalytics/Interactiveisualizations.png'),
	},
	moduleapp:{
		MobileApp : require ('./feature/MobileApp/MobileApp.png')
	},
	ListView:{
		Listview: require ('./feature/ListView/listview.png'),
		Configured: require ('./feature/ListView/Configured.svg').default,
	},
	contact:{
	   Address: require('./contact/address.svg').default,
	   Call: require('./contact/call.svg').default,
	   Email: require('./contact/email.svg').default,
	   Hours: require('./contact/hours.svg').default,
	},
	IntelligentSystem:{
		ExpenseManagement: require ('./feature/IntelligentSystem/ExpenseManagement.svg').default,
		ApprovalWorkflows: require ('./feature/IntelligentSystem/ApprovalWorkflows.svg').default,
		FieldVisits: require ('./feature/IntelligentSystem/FieldVisits.svg').default,
		IntelligentDashboards: require ('./feature/IntelligentSystem/IntelligentDashboards.svg').default,
		ProjectExpenses: require ('./feature/IntelligentSystem/ProjectExpenses.svg').default,
		TravelManagement: require ('./feature/IntelligentSystem/TravelManagement.svg').default,
    },
	video:{
		FreezeColumns:require('./video/FreezeColumns.mp4'),
		HomeBannerVector:require('./video/HomeBannerVector.webm'),
		AttendenceVedio:require('./video/AttendenceVideo.mp4'),
		Mobilepolicies:require('./video/MobilePolicy.mp4'),
		EmpProfile:require('./video/EmpProfile.mp4'),
		CoreHRHeader:require('./video/CoreHRHeader.mp4'),
		GenerateLetter:require('./video/GenerateLetters.mp4'),
		TeamLocation:require('./video/TeamLocation.mp4'),
		TeamLocationHr:require('./video/TeamLocationHr.mp4'),
		ExpensesHeader:require('./video/ExpensesHeader.mp4'),
		BiometricIntegration:require('./video/BiometricIntegration.mp4'),
		AutomatedProces:require('./video/AutomatedProces.mp4'),
		FieldForceAutomationHeader:require('./video/FieldForceAutomationHeader.mp4'),	
		ProjectExpenses:require('./video/ProjectExpenses.mp4'),
		ExpenseAnalytics:require('./video/ExpenseAnalytics.mp4'),
		FieldForceAnalytics:require('./video/FieldForceAnalytics.mp4'),
		AlertNotification:require('./video/AlertNotification.mp4'),
		AlertNotificationRepeat:require('./video/AlertNotificationRepeat.mp4'),
		EscalationAlert:require('./video/EscalationAlerts.mp4'),
		TimeAttendanceHeaders:require('./video/TimeAttendanceHeader.mp4'),
		HRPayroll:require('./video/HRPayroll.mp4'),
		AutomateWorkflowHeader:require('./video/AutomateWorkflowHeader.mp4'),
		QkrdeskHearders:require('./video/QkrdeskHearders.mp4'),
		Helpdesk:require('./video/Helpdesk.mp4'),
		TeamsHeader:require('./video/TeamsHeader.mp4'),
		DocumentsHeader:require('./video/DocumentsHeader.mp4'),
		TabsSpredsheet:require('./video/TabsSpredsheet.mp4'),
		TabsCards:require('./video/TabsCards.mp4'),
		SalesVisitsVideo: require ('./video/SalesVisits.mp4'),
		ProjectVisits: require ('./video/ProjectVisits.mp4'),
	},
	// corehr
	coreHr:{
		hrPolicies: require('./hrms/corehr/hrPolicies.png'),
		rolesPermissions: require('./hrms/corehr/rolesPermissions.png'),
		assetTracking: require('./hrms/corehr/assetTracking.png'),
		certificateManagement: require('./hrms/corehr/certificateManagement.png'),
		auditTrail: require('./hrms/corehr/auditTrail.png'),
		employeeService: require('./hrms/corehr/employeeService.png'),
		coreDocument:require('./hrms/corehr/coreDocument.png'),
		employeeEngagement:require ('./hrms/corehr/employeeEngagement.png'),
	},
	employeeDb:{
		employeeHeader:require('./hrms/corehr/employeeDb/employeeHeader.jpg'),
		kanbanFullFinal:require('./hrms/corehr/employeeDb/kanbanFullFinal.png'),
		kanbanrecRuitment:require('./hrms/corehr/employeeDb/kanbanrecRuitment.png'),
		kanbanEmpTranstion:require('./hrms/corehr/employeeDb/kanbanEmpTranstion.png'),
		employeeProfile: require('./hrms/corehr/employeeDb/employeeProfile.png'),
		orgStructure: require('./hrms/corehr/employeeDb/orgStructure.png'),
		employeeDirectory: require('./hrms/corehr/employeeDb/employeeDirectory.png'),
		orgQuickLinks: require('./hrms/corehr/employeeDb/orgQuickLinks.png'),
		employeeProDesktop: require ('./hrms/corehr/employeeDb/employeeProDesktop.png'),
		employeeProMobile: require ('./hrms/corehr/employeeDb/employeeProMobile.png'),
	},
	// ess
	essPortal: {
		essPortalHearder: require('./hrms/corehr/essPortal/essPortalHearder.png'),
		personalInfo: require('./hrms/corehr/essPortal/personalInfo.png'),
		trackAttendance: require('./hrms/corehr/essPortal/trackAttendance.png'),
		viewPayrollInfo: require('./hrms/corehr/essPortal/viewPayrollInfo.png'),
		leaveDashboard: require('./hrms/corehr/essPortal/leaveDashboard.png'),
		reportingHierarchy: require('./hrms/corehr/essPortal/reportingHierarchy.png'),
		accessPaySlip: require('./hrms/corehr/essPortal/accessPaySlip.png'),
	},
	hrDocument:{
		employeeRelatedDoc: require('./hrms/corehr/hrDocument/employeeRelatedDoc.png'),
		generateLetters: require('./hrms/corehr/hrDocument/generateLetters.png'),
		eSignatures: require('./hrms/corehr/hrDocument/eSignatures.png'),
		offerLetter: require('./hrms/corehr/hrDocument/offerLetter.png'),
		customForms: require('./hrms/corehr/hrDocument/customForms.png'),
	},
	hrWorkflow:{
		approvals: require('./hrms/corehr/hrWorkflow/approvals.png'),
		hrWorkflows: require('./hrms/corehr/hrWorkflow/hrWorkflows.png'),
	},
	hrReports:{
		visitAnalytics: require('./hrms/corehr/hrReports/visitAnalytics.png'),
		visitReports: require('./hrms/corehr/hrReports/visitReports.png'),
		leaveLedgerReport: require('./hrms/corehr/hrReports/leaveLedgerReport.png'),
		movementsRecord: require('./hrms/corehr/hrReports/movementsRecord.png'),
		employeeAtteSheet: require('./hrms/corehr/hrReports/employeeAtteSheet.png'),
	},
	fieldForceAuto:{
		fieldForceHeader:require('./hrms/fieldForceAutomation/fieldForceHeader.jpg'),
		fieldForecVisitAnalytics:require('./hrms/corehr/hrReports/visitAnalytics.png'),
		mobileAttendance:require('./hrms/fieldForceAutomation/mobileAttendance.png'),
		fieldForecVisitReports: require('./hrms/fieldForceAutomation/VisitReports.png'),
	},
	hr:{ 
		QkrHrBg: require ('./HR/QkrHrBg.png'),
		QkrHrHeader: require ('./HR/QkrHrHeader.png'),
		hrms : require ('./HR/Hrms.png'),
		TabHrmsBg: require ('./HR/HrmsBg.png'),
	    //Core HR
		 CoreHrBG: require ('./HR/CoreHr/CoreHrBG.png'),
		 CoreHRHeader : require ('./HR/CoreHr/CoreHRHeader.jpg'),
		//  fullFinal: require ('./HR/CoreHr/fullFinal.png'),
		//  recruitment: require ('./HR/CoreHr/recruitment.png'),
		//  employeeTranstion: require ('./HR/CoreHr/employeeTranstion.png'),
		 EmployeeProfiles : require ('./HR/CoreHr/EmployeeProfiles.png'),
		 Documents : require ('./HR/CoreHr/Documents.png'),
		 HRPolicies : require ('./HR/CoreHr/HRPolicies.png'),
		 RolesPermissions : require ('./HR/CoreHr/RolesPermissions.png'),
		 GenerateLetters : require ('./HR/CoreHr/GenerateLetters.jpg'),
		 AssetTracking : require ('./HR/CoreHr/AssetTracking.png'),
		 HRWorkflows : require ('./HR/CoreHr/HRWorkflows.png'),
		 AlertsNotifications : require ('./HR/CoreHr/AlertsNotifications.png'),
		 CertificationManagement : require ('./HR/CoreHr/CertificationManagement.png'),
		 Approvals : require ('./HR/CoreHr/Approvals.png'),
		 OrganizationStructure : require ('./HR/CoreHr/OrganizationStructure.png'),
		 EmployeeDirectory : require ('./HR/CoreHr/EmployeeDirectory.png'),
		 AutitTrial : require ('./HR/CoreHr/auditTrial.png'),
		 DigitalSignatures:require ('./HR/CoreHr/DigitalSignatures.png'),
		 OrganizationQuick:require ('./HR/CoreHr/OrganizationQuick.png'),
		 OfferLetter:require ('./HR/CoreHr/OfferLetter.png'),
		 SalaryConfig:require ('./HR/CoreHr/SalaryConfig.png'),
		 CustomForm:require ('./HR/CoreHr/CustomForm.png'),	
	   //Field Force
	     FieldForceHeader : require ('./HR/FieldForce/FieldForceHeader.png'),
		 FieldForceAutomationHeader: require ('./HR/FieldForce/FieldForceAutomationHeader.jpg'),
         GPSMobileAttendance : require ('./HR/FieldForce/GPSMobileAttendance.png'),
		 FieldVisits : require ('./HR/FieldForce/FieldVisits.png'),
		 LiveTeamLocation : require ('./HR/FieldForce/LiveTeamLocation.png'),
		 LiveTracking : require ('./HR/FieldForce/LiveTracking.png'),
		 SelfieWithPunch : require ('./HR/FieldForce/SelfieWithPunch.png'),
		 SalesVisits : require ('./HR/FieldForce/SalesVisits.png'),
		 ServiceVisits : require ('./HR/FieldForce/ServiceVisits.png'),
		 ProjectVisits : require ('./HR/FieldForce/ProjectVisits.png'),
		 Mileage : require ('./HR/FieldForce/Mileage.png'),
		 MovementReports : require ('./HR/FieldForce/MovementReports.png'),
		 CustomerLocations : require ('./HR/FieldForce/CustomerLocations.png'),
		 Geofencing : require ('./HR/FieldForce/Geofencing.png'),
		 MinimizeDeviations : require ('./HR/FieldForce/MinimizeDeviations.png'),
		 InDepthReporting : require ('./HR/FieldForce/InDepthReporting.png'),
		 FieldForceAnalytics: require ('./HR/FieldForce/FieldForceAnalytics.jpg'),
		 InstantDocuments : require ('./HR/FieldForce/InstantDocuments.png'),
		 OnSiteTeam : require ('./HR/FieldForce/OnSiteTeam.png'),
		 SupervisorManagedAttendance : require ('./HR/FieldForce/SupervisorManagedAttendance.png'),
		 MobilePolicy:require('./HR/FieldForce/MobilePolicy.jpg'),
		 ConfigurableMobilePolicies:require('./HR/FieldForce/ConfigurableMobilePolicies.png'),
		 //Attendance
		 TimeAttendanceHeader : require ('./HR/Attendance/TimeAttendanceHeader.png'),
		 AttendancePolicies : require ('./HR/Attendance/AttendancePolicies.png'),
		 AttendanceCalendar : require ('./HR/Attendance/AttendanceCalendar.png'),
		 TeamLeaveCalendar : require ('./HR/Attendance/TeamLeaveCalendar.png'),
		 BiometricIntegration : require ('./HR/Attendance/BiometricIntegration.png'),
		 GPSMobileTracking : require ('./HR/Attendance/GPSMobileTracking.png'),
		 PayrollAutomation : require ('./HR/Attendance/PayrollAutomation.png'),
		 OvertimeCalculations : require ('./HR/Attendance/OvertimeCalculations.png'),
		 AttendanceRegularization : require ('./HR/Attendance/AttendanceRegularization.png'),
		 CompOffs : require ('./HR/Attendance/CompOffs.png'),
		 HolidayCalendars : require ('./HR/Attendance/HolidayCalendars.png'),
		 ShiftManagement : require ('./HR/Attendance/ShiftManagement.png'),
		 DeviationPolicy : require ('./HR/Attendance/DeviationPolicy.png'),
		 ShiftRosters : require ('./HR/Attendance/ShiftRosters.png'),
		 ShiftCalendar : require ('./HR/Attendance/ShiftCalendar.png'),
		 LeavePolicies : require ('./HR/Attendance/LeavePolicies.png'),
		 ApplyLeaves : require ('./HR/Attendance/ApplyLeaves.png'),
		 EmployeeSelfService : require ('./HR/Attendance/EmployeeSelfService.png'),
		 LeaveApproval : require ('./HR/Attendance/LeaveApproval.png'),
		 LeavePayrollCalculations : require ('./HR/Attendance/LeavePayrollCalculations.png'),
		 ProbationNoticePeriod : require ('./HR/Attendance/ProbationNoticePeriod.png'),
		 LeaveBalance : require ('./HR/Attendance/LeaveBalance.png'),
		 //paroll
		 PayrollHeader : require ('./HR/Payroll/PayrollHeader.jpg'),
		 ConfigurableRules : require ('./HR/Payroll/ConfigurableRules.png'),
		 SingleConsole : require ('./HR/Payroll/SingleConsole.png'),
		 GenerateBankLetters : require ('./HR/Payroll/GenerateBankLetters.png'),
		 EasyloanTrackingEmi : require ('./HR/Payroll/EasyloanTrackingEmi.png'),
		 RevisingSalary : require ('./HR/Payroll/RevisingSalary.png'),
		 EmployeeCentric : require ('./HR/Payroll/EmployeeCentric.png'),
		 KeepTtrackRevision : require ('./HR/Payroll/KeepTtrackRevision.png'),
		 StatutoryCompliances: require ('./HR/Payroll/StatutoryCompliances.png'),
		 //mobile screen
		 QKrbizEmployeeProDesktope: require ('./HR/CoreHr/QKrbizEmployeePro-desktop.png'),
		 QKrbizEmployeeProMobile: require ('./HR/CoreHr/QKrbizEmployeePro-mobile.png'),
		 QKrbizParollDesktop: require ('./HR/Payroll/QKrbizParoll-desktop.png'),
		 QKrbizParollMobile: require ('./HR/Payroll/QKrbizParoll-mobile.png'),
		 QKrbizAttendanceDesktop: require ('./HR/Attendance/QKrbizAttendance-desktop.png'),
		 QKrbizAttendanceMobile: require ('./HR/Attendance/QKrbizAttendance-mobile.png'),
		 QKrbizFieldForceDesktop: require ('./HR/FieldForce/QKrbizFieldForce-desktop.png'),
		 QKrbizFieldForceMobile: require ('./HR/FieldForce/QKrbizFieldForce-mobile.png'),
		//Engagement
		EngagementHeader: require ('./HR/Engagement/EngagementHeader.png'),
		Announcement:require ('./HR/Engagement/Announcement.png'),
		Feeds:require ('./HR/Engagement/Feeds.png'),
		Praise:require ('./HR/Engagement/Praise.png'),
		QKrbizPoll: require ('./HR/Engagement/Poll.png'),
		QKrbizFeedback: require ('./HR/Engagement/Feedback.png'),
		QKrbizInternalNotes: require ('./HR/Engagement/InternalNotes.png'),
		QKrbizChat: require ('./HR/Engagement/Chat.png'),
	},
	expense:{
		QkrExpenseHeader: require ('./Qkrexpense/QkrexpenseHearder.jpg'),

		ForEmployees: require ('./Qkrexpense/ForEmployees.png'),
		ForManagers: require ('./Qkrexpense/ForManagers.png'),
		ForOrganization: require ('./Qkrexpense/ForOrganization.png'),

		AutomateExpense: require ('./Qkrexpense/AutomateExpense.png'),
		ExpenseRequest: require ('./Qkrexpense/ExpenseRequest.svg').default,
		ExpenseApproval: require ('./Qkrexpense/ExpenseApproval.svg').default,
		PaymentExpenses: require ('./Qkrexpense/PaymentExpenses.svg').default,

		FeaturesHead: require ('./Qkrexpense/FeaturesHead.png'),
		FeaturesHead2: require ('./Qkrexpense/FeaturesHead2.png'),
		ExpenseManagement: require ('./Qkrexpense/ExpenseManagement.svg').default,
		TravelManagement: require ('./Qkrexpense/TravelManagement.svg').default,
		FieldVisitsMileage: require ('./Qkrexpense/FieldVisitsMileage.svg').default,
		AdvancesDisbursement: require ('./Qkrexpense/AdvancesDisbursement.svg').default,
		ProjectExpenses: require ('./Qkrexpense/ProjectExpenses.svg').default,
		ApprovalWorkflows: require ('./Qkrexpense/ApprovalWorkflows.svg').default,
		IntelligentDashboards: require ('./Qkrexpense/IntelligentDashboards.svg').default,
		ActionableAnalytics: require ('./Qkrexpense/ActionableAnalytics.svg').default,

        ExpenseClaims: require ('./Qkrexpense/ExpenseClaims.png'),
		MileageTracking: require ('./Qkrexpense/MileageTracking.png'),
		TravelDesk: require ('./Qkrexpense/TravelDesk.png'),

		AdvancesExpensReimbursements: require ('./Qkrexpense/AdvancesExpensReimbursements.png'),
		ConfigurableWorkflows: require ('./Qkrexpense/ConfigurableWorkflows.png'),

		ProjectExpense: require ('./Qkrexpense/ProjectExpenses.png'),
		Analytics: require ('./Qkrexpense/Analytics.png'),
    },
	desk:{
		QkrdeskHearder: require ('./Qkrdesk/QkrdeskHearder.png'),

		Deskfeatures_1: require ('./Qkrdesk/Deskfeatures_1.png'),
		Deskfeatures_2: require ('./Qkrdesk/Deskfeatures_2.png'),
		TicketsManagement: require ('./Qkrdesk/TicketsManagement.svg').default,
		ServiceContractManagement: require ('./Qkrdesk/ServiceContractManagement.svg').default,
		ManageFieldVisits: require ('./Qkrdesk/ServiceContractManagement.svg').default,
		WebBasedAccess: require ('./Qkrdesk/WebBasedAccess.svg').default,
		ProcessManagement: require ('./Qkrdesk/ProcessManagement.svg').default,
		ActIntelligence: require ('./Qkrdesk/ActIntelligence.svg').default,
		GrowWithoutGrowing: require ('./Qkrdesk/GrowWithoutGrowing.svg').default,
		LeadCustomers: require ('./Qkrdesk/LeadCustomers.svg').default,
		ConnectingBusiness: require ('./Qkrdesk/ConnectingBusiness.svg').default,
		Ticketing_bg: require ('./Qkrdesk/Ticketing_bg.png'),
		ConnectingDepartment: require ('./Qkrdesk/ConnectingDepartment.png'),
		StreamlineConversations: require ('./Qkrdesk/StreamlineConversations.svg').default,
		CollaborateBetter: require ('./Qkrdesk/CollaborateBetter.svg').default,
		StayProductive: require ('./Qkrdesk/StayProductive.svg').default,
	},
	sales:{
		QkrsalesHearder: require ('./qkrsales/QkrsalesHearder.png'),
		SalesOrder_1: require ('./qkrsales/SalesOrder_1.png'),
		SalesOrder_2: require ('./qkrsales/SalesOrder_2.png'),
		SalesOrder_3: require ('./qkrsales/SalesOrder_3.png'),
		// WeContinueFaceChallenges: require ('./qkrsales/WeContinueFaceChallenges.png'),
		// Workflow: require ('./qkrsales/Workflow.svg').default,
		// Notifications: require ('./qkrsales/Notifications.svg').default,
		// Reminders: require ('./qkrsales/Reminders.svg').default,
		// Alerts: require ('./qkrsales/Alerts.svg').default,
		// PerformanceView: require ('./qkrsales/PerformanceView.svg').default,
		// BusinessIntelligence: require ('./qkrsales/BusinessIntelligence.svg').default,
		// HighlyConfigurable:require ('./qkrsales/HighlyConfigurable.svg').default,
		// AuditTrail:require ('./qkrsales/AuditTrail.svg').default,
		// DocumentManagement:require ('./qkrsales/DocumentManagement.svg').default,
		TakeHassleOut: require ('./qkrsales/TakeHassleOut.svg').default,
		UnlockCapital: require ('./qkrsales/UnlockCapital.svg').default,
		SpeedCollection: require ('./qkrsales/SpeedCollection.svg').default,
		BuildStrongProcess: require ('./qkrsales/BuildStrongProcess.svg').default,
		GetCrystal: require ('./qkrsales/GetCrystal.svg').default,
		BuildAccountability: require ('./qkrsales/BuildAccountability.svg').default,
		ImprovePerformance: require ('./qkrsales/ImprovePerformance.svg').default,
		ChnageHabits: require ('./qkrsales/ChnageHabits.svg').default,	
		SalesManagementHeader: require ('./qkrsales/SalesManagementHeader.png'),
		AccountsReceivableHeader: require ('./qkrsales/AccountsReceivableHeader.png'),
		OrderExecutionHeader: require ('./qkrsales/OrderExecutionHeader.png'),
		BillingPaymentHeader: require ('./qkrsales/BillingPaymentHeader.png'),
		Invoicing: require ('./qkrsales/Invoicing.png'),
		SalesPerformanceHeader: require ('./qkrsales/SalesPerformanceHeader.png'),
		TenderDepositHeader: require ('./qkrsales/TenderDepositHeader.png'),
		SalesEffortlessSystem: require ('./qkrsales/SalesEffortlessSystem.png'),
		SalesQkrbizPrimed: require ('./qkrsales/SalesQkrbizPrimed.png'),
		AccountsReceivableHead:require ('./qkrsales/AccountsReceivable/AccountsReceivableHeader.png'),
		TrackPayments:require ('./qkrsales/AccountsReceivable/TrackPayments.png'),
		AllinOnePlatform:require ('./qkrsales/AccountsReceivable/AllinOnePlatform.png'),
		MinimizeForecast:require ('./qkrsales/AccountsReceivable/MinimizeForecast.png'),     
	},
	Qkrteams:{
		QkrteamsHeader: require ('./Qkrteams/QkrteamsHeader.png'),
		EffortlessExperienceBody:require('./Qkrteams/EffortlessExperienceBody.png'),
		HistoryBody:require('./Qkrteams/HistoryBody.png'),
		TaskViewBody:require('./Qkrteams/TaskViewBody.png'),
		Reporting:require('./Qkrteams/Reporting.png'),
		ImportantTasks:require('./Qkrteams/ImportantTasks.png'),
		Renewals:require('./Qkrteams/Renewals.png'),
		Compliances:require('./Qkrteams/Compliances.png'),
		StartDates:require('./Qkrteams/StartDates.png'),
		RecurringTask:require('./Qkrteams/RecurringTask.png'),
		StopAfter:require('./Qkrteams/StopAfter.png'),
		AssignmentDate:require('./Qkrteams/AssignmentDate.png'),
		CustomFields:require('./Qkrteams/CustomFields.png'),
		Priorities:require('./Qkrteams/Priorities.png'),
		HierarchyBasedAccess:require('./Qkrteams/HierarchyBasedAccess.png'),
		RemindersForEverything:require('./Qkrteams/RemindersForEverything.png'),
		ViewHistory:require('./Qkrteams/ViewHistory.png'),
		WorkNotDoneClosure:require('./Qkrteams/WorkNotDoneClosure.png'),
		Category:require('./Qkrteams/Category.png'),
		Filters:require('./Qkrteams/Filters.png'),
	},
	QkrTenders:{
		Qkrtenderheader: require ('./Qkrtenders/Qkrtenderheader.png'),
		BiddingStatus: require ('./Qkrtenders/BiddingStatus.png'),
		TendersFeature_1: require ('./Qkrtenders/TendersFeature_1.png'),
		TendersFeature_2: require ('./Qkrtenders/TendersFeature_2.png'),
		DepositRequests: require ('./Qkrtenders/DepositRequests.svg').default,
		BankProcessing: require ('./Qkrtenders/BankProcessing.svg').default,
		BidManagement: require ('./Qkrtenders/BidManagement.svg').default,
		CollectDeposits: require ('./Qkrtenders/CollectDeposits.svg').default,
		ExtensionsAmendments: require ('./Qkrtenders/ExtensionsAmendments.svg').default,
		PerformanceMonitoring: require ('./Qkrtenders/PerformanceMonitoring.svg').default,
		GenerateLetters: require ('./Qkrtenders/GenerateLetters.svg').default,
		TendersEffortlessSystem: require ('./Qkrtenders/TendersEffortlessSystem.png'),
		TendersQkrbizPrimed: require ('./Qkrtenders/TendersQkrbizPrimed.png'),
		DespiteOurGrowing: require ('./Qkrtenders/DespiteOurGrowing.png'),
	},
	QkrProjects:{
		Qkrprojecthearder: require ('./Qkrprojects/Qkrprojecthearder.png'),
		QkrprojecFeature_1: require ('./Qkrprojects/QkrprojecFeature_1.png'),
		WorkOrder: require ('./Qkrprojects/WorkOrder.svg').default,
		MilestoneManagement: require ('./Qkrprojects/MilestoneManagement.svg').default,
		DocumentManagement: require ('./Qkrprojects/DocumentManagement.svg').default,
		ApprovalWorkflow: require ('./Qkrprojects/ApprovalWorkflow.svg').default,
		BudgetingExpens: require ('./Qkrprojects/BudgetingExpens.svg').default,
		ManpowerExpenses: require ('./Qkrprojects/ManpowerExpenses.svg').default,
		RenewalManagement: require ('./Qkrprojects/RenewalManagement.svg').default,
	}
};

export default ImagesUrl;

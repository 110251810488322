import React, { Suspense, useEffect, lazy } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.scss";
import RouteSpecificMeta from "./utility/Seo/RouteSpecificMeta";
import Header from "./shared/Header/Header";
import Footer from "./shared/Footer/Footer";
import Loading from "./utility/Loading/Loading";
import Home from "./Pages/Home/Home";
const Customers = lazy(() => import("./Pages/Customers/Customers"));
const About = lazy(() => import("./Pages/About/About"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const Signup = lazy(() => import("./Pages/Signup/Signup"));
const Terms = lazy(() => import("./Pages/Terms/Terms"));
const Privacy = lazy(() => import("./Pages/Privacy/Privacy"));
//resource
const Views = lazy(()=> import("./Pages/Views/Views"));
const Features = lazy(()=> import("./Pages/Features/Features"));
const Alertnotification = lazy(()=> import("./Pages/Alertnotification/Alertnotification"));
const Workflow = lazy(()=> import("./Pages/Workflow/Workflow"));
const Documents = lazy(()=> import("./Pages/Documents/Documents"));
const Security = lazy(()=> import("./Pages/Security/Security")) ;
const Bianalytics = lazy(()=> import("./Pages/Bianalytics/Bianalytics"));
const Customizations = lazy(()=> import("./Pages/Customizations/Customizations"));
const Mobileapp = lazy(()=> import("./Pages/Mobileapp/Mobileapp"));
const BoardView = lazy(()=> import("./Pages/BoardView/BoardView"));
// const ListView = lazy(()=> import("./Pages/ListView/ListView"));
const Notfound = lazy(() => import("./components/NotFound/Notfound"));
//hr
const HrmsLayout = lazy(() => import("./module/Hrms/HrmsLayout/HrmsLayout"));
const HrmsHome = lazy(() => import("./module/Hrms/HrmsHome/HrmsHome"));
const CoreHrLayout = lazy(() => import("./module/Hrms/CoreHr/CoreHrLayout/CoreHrLayout"));
const CoreHrHome = lazy(() => import("./module/Hrms/CoreHr/CoreHrHome/CoreHrHome"));
const EmployeeDatabase = lazy(() => import("./module/Hrms/CoreHr/EmployeeDatabase/EmployeeDatabase"));
const EssPortal = lazy(() => import("./module/Hrms/CoreHr/EssPortal/EssPortal"));
const Document = lazy(() => import("./module/Hrms/CoreHr/Document/Document"));
const Workflows = lazy(() => import("./module/Hrms/CoreHr/Workflows/Workflows"));
const HrReports = lazy(() => import("./module/Hrms/CoreHr/HrReports/HrReports"));

// const FieldForceLayout = lazy(() => import("./module/Hrms/FieldForceAutomation/FieldForceLayout/FieldForceLayout"));
// const FieldForceAutomations = lazy(() => import("./module/Hrms/FieldForceAutomation/FieldForceAutomations/FieldForceAutomations"));

// const TimeAttendanceLayout = lazy(() => import("./module/Hrms/TimeAttendance/TimeAttendanceLayout/TimeAttendanceLayout"));
// const TimeAttendance = lazy(() => import("./module/Hrms/TimeAttendance/TimeAttendance/TimeAttendance"));
// const LeaveManagement = lazy(() => import("./module/Hrms/TimeAttendance/LeaveManagement/LeaveManagement"));
// const ShiftManagement = lazy(() => import("./module/Hrms/TimeAttendance/ShiftManagement/ShiftManagement"));

// const PayrollsLayout = lazy(() => import("./module/Hrms/Payrolls/PayrollsLayout/PayrollsLayout"));
// const Payrolls = lazy(() => import("./module/Hrms/Payrolls/Payrolls/Payrolls"));
// const StatutoryCompliance = lazy(() => import("./module/Hrms/Payrolls/StatutoryCompliance/StatutoryCompliance"));

//hr old
const HrLayout = lazy(()=> import("./module/HR/HrLayout/HrLayout"));
const HrHome = lazy(()=> import("./module/HR/HrHome/HrHome"));
// const CoreHr = lazy(()=> import("./module/HR/CoreHr/CoreHr"));
const FieldForce = lazy(()=> import("./module/HR/FieldForce/FieldForce"));
const Attendance = lazy(()=> import("./module/HR/Attendance/Attendance"));
const Payroll = lazy(()=> import("./module/HR/Payroll/Payroll"));
const Expense = lazy(()=> import("./module/HR/Expense/Expense"));
const Tasks = lazy(()=> import("./module/HR/Tasks/Tasks"));
const Engagement = lazy(()=> import("./module/HR/Engagement/Engagement"));
const Pricing = lazy(()=> import("./module/HR/Pricing/Pricing"));
//expense
const ExpenseLayout = lazy(()=> import("./module/Expense/ExpenseLayout/ExpenseLayout"));
const ExpenseHome = lazy(()=> import("./module/Expense/ExpenseHome/ExpenseHome"));
//sales
const SalesLayout = lazy(() => import("./module/Sales/SalesLayout/SalesLayout"));
const SalesHome = lazy(() => import("./module/Sales/SalesHome/SalesHome"));
const SalesFeauterLayout = lazy(() => import("./module/Sales/Feauters/SalesFeauterLayout/SalesFeauterLayout"));
const SalesManagement = lazy(() => import("./module/Sales/Feauters/SalesManagement/SalesManagement"));
const AccountsReceivable = lazy(()=> import("./module/Sales/Feauters/AccountsReceivable/AccountsReceivable"));
const BillingPayment = lazy(()=> import("./module/Sales/Feauters/BillingPayment/BillingPayment"));
const OrderExecution = lazy(()=> import("./module/Sales/Feauters/OrderExecution/OrderExecution"));
const Invoicving = lazy(()=> import("./module/Sales/Feauters/Invoicving/Invoicving"));
const TenderDeposit = lazy(()=> import("./module/Sales/Feauters/TenderDeposit/TenderDeposit"));
const SalesPerformance = lazy(()=> import("./module/Sales/Feauters/SalesPerformance/SalesPerformance"));
const DocumentManagement = lazy(()=> import("./module/Sales/Feauters/DocumentManagement/DocumentManagement"));
const BusinessManagement = lazy(()=> import("./module/Sales/Feauters/BusinessManagement/BusinessManagement"));

//teams
const TeamsHome = lazy(()=> import("./module/Teams/TeamsHome/TeamsHome"));
const TeamsLayout = lazy(()=> import("./module/Teams/TeamsLayout/TeamsLayout"));
//visit
const VisitLayout = lazy(()=> import("./module/Visit/VisitLayout/VisitLayout"));
const VisitHome = lazy(()=> import("./module/Visit/VisitHome/VisitHome"));

//desk
const DeskHome = lazy(()=> import("./module/Desk/DeskHome/DeskHome"));
const DeskLayout = lazy(()=> import("./module/Desk/DeskLayout/DeskLayout"));
//Tenders
const TendersHome = lazy(()=> import("./module/Tenders/TendersHome/TendersHome"));
const TendersLayout = lazy(()=> import("./module/Tenders/TendersLayout/TendersLayout"));
//Project
const ProjectHome = lazy(()=> import("./module/Project/ProjectHome/ProjectHome"));
const ProjectLayout = lazy(()=> import("./module/Project/ProjectLayout/ProjectLayout"));

// const AdminLayout = lazy(()=> import("./AdminLayout"));
// const Login = lazy(()=> import("./Admin/Login/Login"));
// const ProductList = lazy(()=> import("./Admin/ProductList/ProductList"));
// const PostData = lazy(()=> import("./Admin/PostData/PostData"));
// const ImageCompressor = lazy(()=> import("./Admin/ImageCompressor/ImageCompressor"));
// const ImageToPdfConverter = lazy(()=> import("./Admin/ImageToPdfConverter/ImageToPdfConverter"));
// const UserLayout = lazy(()=> import("./Admin/Users/UserLayout"));
// const UserList = lazy(()=> import("./Admin/Users/UserList"));
// const UserForm = lazy(()=> import("./Admin/Users/UserForm"));

function App() {
  const location = useLocation();
  const allowedPaths = [ '/','/hrms-software','/hrms-software/','/qkrexpense','/qkrsales','/qkrteams', '/qkrvisit', '/qkrdesk', '/qkrtenders','/qkrprojects', '/about', '/customers', '/contact', '/terms', '/privacy', '/views', '/features', '/alertnotification', '/workflow',
    '/documents', '/security', '/bianalytics', '/customizations', '/mobileapp', '/boardview', '/signup', '*' ];
  const isDisplayHeader = allowedPaths.includes(location.pathname);

  // const location = useLocation();
  // const allowedPaths = [
  //   '/', '/about', '/customers', '/contact', '/terms', '/privacy',
  //   '/views', '/features', '/alertnotification', '/workflow', '/documents',
  //   '/security', '/bianalytics', '/customizations', '/mobileapp', '/boardview', 
  //   '/signup', '*'
  // ];
  // const matchWildcardPath = (path) => {
  //   return allowedPaths.includes('*') || allowedPaths.includes(path);
  // };
  // const isDisplayHeader = matchWildcardPath(location.pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
 
  return (
    <>
      {isDisplayHeader && (
          <Header />
      )}
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <RouteSpecificMeta
                  title="Qkrbiz | SaaS applications for Business"
                  description="Qkrbiz helps you plug silent leakage of your profits by automating your business processes and improving the productivity of your employees."
                  keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India"
                  canonical="https://www.qkrbiz.com/"
                />
                <Home />
              </>
            }
          />
          <Route
            exact
            path="/customers"
            element={
              <>
                <RouteSpecificMeta
                  title="Qkrbiz | Clients"
                  description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                  keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India"
                  canonical="https://www.qkrbiz.com/"
                />
                <Customers />
              </>
            }
          />
          <Route
            exact
            path="/about"
            element={
              <>
                <RouteSpecificMeta
                title="Qkrbiz | About Us"
                description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India"
                canonical="https://www.qkrbiz.com/" 
                />
                <About />
              </>
            }
          />
          <Route
            exact
            path="/contact"
            element={
              <>
                  <RouteSpecificMeta
                  title="Qkrbiz | Contact Us"
                  description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                  keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                  canonical="https://www.qkrbiz.com/" />
                  <Contact />
              </>
            }
          />
          <Route
            exact
            path="/terms"
            element={
              <>
                <RouteSpecificMeta
                title="Qkrbiz | Terms and Conditions"
                description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                canonical="https://www.qkrbiz.com/" />
                <Terms />
              </>
            }
          />
          <Route
            exact
            path="/privacy"
            element={
              <>
                <RouteSpecificMeta
                title="Qkrbiz | Privacy Policy"
                description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                canonical="https://www.qkrbiz.com/" />
                <Privacy />
              </>
            }
          />
          <Route
            exact
            path="/views"
            element={
              <>
                <RouteSpecificMeta
                  title="Qkrbiz | Views"
                  description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                  keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India"
                  canonical="https://www.qkrbiz.com/"
                />
                <Views />
              </>
            }
          />
          <Route
            exact
            path="/features"
            element={
              <>
                <RouteSpecificMeta
                  title="Qkrbiz | Spreadsheet Features"
                  description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                  keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India"
                  canonical="https://www.qkrbiz.com/"
                />
                <Features />
              </>
            }
          />
         <Route
            exact
            path="/alertnotification"
            element={
              <>
                <RouteSpecificMeta
                  title="Qkrbiz | Alerts & Notifications"
                  description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                  keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India"
                  canonical="https://www.qkrbiz.com/"
                />
                <Alertnotification />
              </>
            }
          />
          <Route
            exact
            path="/workflow"
            element={
              <>
                <RouteSpecificMeta
                title="Qkrbiz | Workflow Automation"
                description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                canonical="https://www.qkrbiz.com/" />
                <Workflow />
              </>
            }
          />
          <Route
            exact
            path="/documents"
            element={
              <>
                <RouteSpecificMeta
                title="Qkrbiz | Document Management"
                description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                canonical="https://www.qkrbiz.com/" />
                <Documents />
              </>
            }
          />
          <Route
            exact
            path="/security"
            element={
              <>
                <RouteSpecificMeta
                title="Qkrbiz | Data Security"
                description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                canonical="https://www.qkrbiz.com/" />
                <Security />
              </>
            }
          />
          <Route
            exact
            path="/bianalytics"
            element={
              <>
                <RouteSpecificMeta
                title="Qkrbiz | BI & Analytics"
                description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                canonical="https://www.qkrbiz.com/" />
                <Bianalytics />
              </>
            }
          />
          <Route
            exact
            path="/customizations"
            element={
              <>
                <RouteSpecificMeta
                title="Qkrbiz | Customizations"
                description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                canonical="https://www.qkrbiz.com/" />
                <Customizations />
              </>
            }
          />
          <Route
            exact
            path="/mobileapp"
            element={
              <>
                <RouteSpecificMeta
                title="Qkrbiz | Mobile App"
                description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                canonical="https://www.qkrbiz.com/" />
                <Mobileapp />
              </>
            }
          />
          {/* <Route exact path="/listview" element={<ListView />} /> */}
          <Route
            exact
            path="/boardview"
            element={
              <>
                <RouteSpecificMeta
                title="Qkrbiz | Board View"
                description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                canonical="https://www.qkrbiz.com/" />
                <BoardView />
              </>
            }
          />
          <Route
            exact
            path="/signup"
            element={
              <>
                <RouteSpecificMeta
                title="Qkrbiz | Free Trial"
                description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                canonical="https://www.qkrbiz.com/" />
                <Signup />
              </>
            }
          />
          {/*Hrms Software */}
          <Route path="hrms-software" element={<HrmsLayout />}>
            <Route index 
              element={
                <>
                  <RouteSpecificMeta
                  title="QkrHR | HRMS Software"
                  description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                  keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                  canonical="https://www.qkrbiz.com/" />
                  <HrmsHome />
                </>
              }
            />
            <Route element={<CoreHrLayout />}>
                <Route index path="core-hr" 
                  element={
                    <>
                      <RouteSpecificMeta
                      title="HRMS Software | Core HR"
                      description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                      keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                      canonical="https://www.qkrbiz.com/" />
                      <CoreHrHome />
                    </>
                  }
                />
                <Route path="employee–management" 
                  element={
                    <>
                      <RouteSpecificMeta
                      title="HRMS Software | Core HR | Employee Database"
                      description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                      keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                      canonical="https://www.qkrbiz.com/" />
                      <EmployeeDatabase />
                    </>
                  }
                />
                <Route path="employee-self-service-portal" 
                  element={
                    <>
                      <RouteSpecificMeta
                      title="HRMS Software | Core HR | ESS Portal"
                      description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                      keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                      canonical="https://www.qkrbiz.com/" />
                      <EssPortal />
                    </>
                  }
                />
                <Route path="document-management" 
                  element={
                    <>
                      <RouteSpecificMeta
                      title="HRMS Software | Core HR | Documents"
                      description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                      keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                      canonical="https://www.qkrbiz.com/" />
                      <Document />
                    </>
                  }
                />
                <Route path="workflow-management" 
                  element={
                    <>
                      <RouteSpecificMeta
                      title="HRMS Software | Core HR | Workflow"
                      description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                      keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                      canonical="https://www.qkrbiz.com/" />
                      <Workflows />
                    </>
                  }
                />
                <Route path="hr-data-analytics" 
                  element={
                    <>
                      <RouteSpecificMeta
                      title="HRMS Software | Core HR | HR Reports"
                      description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                      keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                      canonical="https://www.qkrbiz.com/" />
                      <HrReports />
                    </>
                  }
              />
            </Route>

            {/* <Route element={<FieldForceLayout />}>
              <Route index path="field-force-automation" element={<FieldForceAutomations />} />
            </Route>
            <Route element={<TimeAttendanceLayout />}>
                <Route index path="time-and-attendance" element={<TimeAttendance />} />
                <Route path="leave-management" element={<LeaveManagement />} />
                <Route path="shift-management" element={<ShiftManagement />} />
            </Route>
            <Route element={<PayrollsLayout />}>
              <Route index path="payroll-management" element={<Payrolls />} />
              <Route path="statutory-compliance" element={<StatutoryCompliance />} />
            </Route> */}

          </Route>

           {/*QkrHr */}
          <Route path="hrms-software" element={<HrLayout />}>
            <Route index
              element={
                <>
                  <RouteSpecificMeta
                  title="QkrHR | HRMS Software"
                  description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                  keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                  canonical="https://www.qkrbiz.com/" />
                  <HrHome />
                </>
              }
            />
            {/* <Route path="core-hr" 
            element={
            <>
              <RouteSpecificMeta
              title="QkrHR | HRMS Software | Core HR"
              description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
              keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
              canonical="https://www.qkrbiz.com/" />
              <CoreHr />
            </>
            } 
            /> */}
            <Route path="field-force" 
            element={
            <>
              <RouteSpecificMeta
              title="QkrHR | HRMS Software | Field Force Automation"
              description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
              keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
              canonical="https://www.qkrbiz.com/" />
              <FieldForce />
            </>
            } 
            />
            <Route path="attendance" 
            element={
            <>
                <RouteSpecificMeta
                title="QkrHR | HRMS Software | Attendance"
                description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                canonical="https://www.qkrbiz.com/" />
                <Attendance />
            </>
            } 
            />
            <Route path="payroll" 
            element={
            <>
                <RouteSpecificMeta
                title="QkrHR | HRMS Software | Payroll"
                description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                canonical="https://www.qkrbiz.com/" />
                <Payroll />
            </>
            } 
            />
            <Route path="expense" 
            element={
            <>
                <RouteSpecificMeta
                title="QkrHR | HRMS Software | Expense"
                description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India"
                canonical="https://www.qkrbiz.com/" />
                <Expense />
            </>
            } 
            />
            <Route path="tasks" 
            element={
            <>
                <RouteSpecificMeta
                title="QkrHR | HRMS Software | Tasks"
                description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                canonical="https://www.qkrbiz.com/" />
                <Tasks />
            </>
            } 
            />
            <Route
              path="price"
              element={
                <>
                  <RouteSpecificMeta
                  title="QkrHR | HRMS Software | Price"
                  description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                  keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                  canonical="https://www.qkrbiz.com/" />
                  <Pricing />
                </>
              }
            />
            <Route path="engagement" 
              element={
                <>
                  <RouteSpecificMeta
                  title="QkrHR | HRMS Software | Employee Engagement"
                  description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                  keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                  canonical="https://www.qkrbiz.com/" />
                  <Engagement />
                </>
              } 
            />
          </Route>

          {/*QkrExpense */}
          <Route path="qkrexpense" element={<ExpenseLayout />}>
            <Route index
              element={
                <>
                  <RouteSpecificMeta
                  title="Qkrexpense | Expense Management Software | Advances, Travel & Expenses"
                  description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                  keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                  canonical="https://www.qkrbiz.com/" />
                  <ExpenseHome />
                </>
              }
            />
          </Route>

          {/*QkrDesk */}
          <Route path="qkrdesk" element={<DeskLayout />}>
              <Route index
                element={
                  <>
                    <RouteSpecificMeta
                    title="Qkrdesk | Help desk software for Happy Customers"
                    description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                    keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                    canonical="https://www.qkrbiz.com/" />
                    <DeskHome />
                  </>
                }
            />
          </Route>
          
          {/*QkrSales */}
          <Route path="qkrsales" element={<SalesLayout />}>
            <Route index 
              element={
                <>
                  <RouteSpecificMeta
                  title="Qkrsales | Sales Management Software | Automate order to cash process"
                  description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                  keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                  canonical="https://www.qkrbiz.com/" />
                  <SalesHome />
                </>
              }
            />
            <Route element={<SalesFeauterLayout />}>
              <Route index path="salesmanagement" 
                  element={
                  <>
                    <RouteSpecificMeta
                    title="Qkrsales | Sales Management Software | Sales Management"
                    description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                    keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                    canonical="https://www.qkrbiz.com/" />
                    <SalesManagement />
                  </>
                }
              />
              <Route path="accountsreceivable" 
                element={
                  <>
                    <RouteSpecificMeta
                    title="Qkrsales | Sales Management Software | Accounts Receivable"
                    description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                    keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                    canonical="https://www.qkrbiz.com/" />
                    <AccountsReceivable />
                  </>
                }
              />
              <Route path="billingpayment" 
                element={
                  <>
                    <RouteSpecificMeta
                    title="Qkrsales | Sales Management Software | Billing & Payment Terms"
                    description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                    keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                    canonical="https://www.qkrbiz.com/" />
                    <BillingPayment />
                  </>
                }
              />
              <Route path="orderexecution" 
                element={
                  <>
                    <RouteSpecificMeta
                    title="Qkrsales | Sales Management Software | Order Execution"
                    description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                    keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                    canonical="https://www.qkrbiz.com/" />
                    <OrderExecution />
                  </>
                }
              />
              <Route path="invoicing" 
                element={
                  <>
                    <RouteSpecificMeta
                    title="Qkrsales | Sales Management Software | Invoicing"
                    description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                    keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                    canonical="https://www.qkrbiz.com/" />
                    <Invoicving />
                  </>
                }
              />
              <Route path="tenderdeposit" 
                element={
                  <>
                    <RouteSpecificMeta
                    title="Qkrsales | Sales Management Software | Tender Deposits"
                    description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                    keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                    canonical="https://www.qkrbiz.com/" />
                    <TenderDeposit />
                  </>
                }
              />
              <Route path="salesperformance" 
                element={
                  <>
                    <RouteSpecificMeta
                    title="Qkrsales | Sales Management Software | Sales Performance"
                    description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                    keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                    canonical="https://www.qkrbiz.com/" />
                    <SalesPerformance />
                  </>
                }
              />
              <Route path="documentmanagement" 
                element={
                  <>
                    <RouteSpecificMeta
                    title="Qkrsales | Sales Management Software | Document Management"
                    description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                    keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                    canonical="https://www.qkrbiz.com/" />
                    <DocumentManagement />
                  </>
              }
              />
              <Route path="BusinessManagement" 
              element={
                <>
                  <RouteSpecificMeta
                  title="Qkrsales | Sales Management Software | Business Management"
                  description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                  keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                  canonical="https://www.qkrbiz.com/" />
                  <BusinessManagement />
                </>
              }
              />
            </Route>
          </Route>

          {/*QkrTeams */}
          <Route path="qkrteams" element={<TeamsLayout />}>
              <Route index
                element={
                  <>
                    <RouteSpecificMeta
                    title="Qkrteams | Task management software | Accelerate your Team Performance"
                    description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                    keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                    canonical="https://www.qkrbiz.com/" />
                    <TeamsHome />
                  </>
                }
              />
          </Route>

          {/*QkrVisit */}
          <Route path="qkrvisit" element={<VisitLayout />}>
            <Route
              index
              element={
                <>
                  <RouteSpecificMeta
                  title="Qkrvisit | Field Force Automation | Location Tracking"
                  description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                  keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                  canonical="https://www.qkrbiz.com/" />
                  <VisitHome />
                </>
              }
            />
          </Route>

          {/*QkrTenders */}
          <Route path="qkrtenders" element={<TendersLayout />}>
              <Route index
                element={
                  <>
                    <RouteSpecificMeta
                    title="Qkrtenders | Tender Management Software"
                    description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                    keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                    canonical="https://www.qkrbiz.com/" />
                    <TendersHome />
                  </>
                }
              />
          </Route>

          {/*QkrProject */}
          <Route path="qkrprojects" element={<ProjectLayout />}>
              <Route index
                element={
                  <>
                    <RouteSpecificMeta
                    title="Qkrprojects | Project Management Software"
                    description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                    keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                    canonical="https://www.qkrbiz.com/" />
                    <ProjectHome />
                  </>
                }
              />
          </Route>

          {/* <Route path="userslist" element={<UserLayout />}>
              <Route index element={<UserList />} />
              <Route exact path="add" element={<UserForm />} />
              <Route exact path="edit/:id" element={<UserForm />} />
          </Route> */}
          {/* <Route exact path="/logins" element={<Login />}/>
          <Route exact path="/productlist" element={<ProductList />}/>
          <Route exact path="/postlist" element={<PostData />}/>
          <Route exact path="/imagecompressor" element={<ImageCompressor />}/>
          <Route exact path="/imagetopdf" element={<ImageToPdfConverter />}/> */}
{/* 
          <Route
            exact
            path="/admin"
            element={
              <>
                <RouteSpecificMeta
                  title="Qkrbiz | Admin"
                  description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                  keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India"
                  canonical="https://www.qkrbiz.com/"
                />
                <AdminLayout />
              </>
             }
          /> */}

          <Route exact
            path="*"
            element={
              <>
                <RouteSpecificMeta
                title="Qkrbiz | Page Not Found"
                description="Qkrbiz automates your business processes and augments the productivity of your employees, leading to more profits"
                keywords="Cloud ERP Software, ERP for Small Business, ERP Software, Workflow Automation Software Enterprise Resource Planning, ERP Module, Enterprise Application, Business Software, Web-Based ERP Software, ERP Solutions, Online ERP Software in India" 
                canonical="https://www.qkrbiz.com/" />
                <Notfound />
              </>
            }
          />
        </Routes>
      </Suspense>
      <Footer />
    </>
  );
}

export default App;

